import { useEffect, useState } from "react"
import Construction from "../../utils/construction"
import Programs from "./programs"
import parse from 'parse'
import { useParams } from "react-router-dom"
import Spinner from "../../utils/spinner"

const Comparison = () => {
    const params = useParams();
    const tabs = [
        { title: "Programas", class: 'program' },
        //{ title: "Financiación", class: 'finance' },
        //{ title: "Becas", class: 'scholarship' },
    ]
    const [current, setCurrent] = useState("program")
    const [career, setCareer] = useState()

    useEffect(() => {
        getCareer()
    }, [])
    const getCareer = async () => {
        let result = await new parse.Query("Career").get(params.id)
        setCareer(result)
    }
    const getTabList = () => {
        let view = <Construction />
        switch (current) {
            case "program": view = <Programs career={career} />; break;
        }
        return view
    }
    if (!career) {
        return <Spinner/>
    } else
        return (
            <div className="pb-5">
                <h1>{career.get("name")}</h1>
                <ul className="nav nav-tabs nav-fill">
                    {tabs.map((tab, index) => {
                        return <li className="nav-item" key={index}>
                            <a className={`nav-link ${tab.class === current ? 'active' : ''}`} aria-current="page" onClick={() => setCurrent(tab.class)}>{tab.title}</a>
                        </li>
                    })}
                </ul>
                {getTabList()}
            </div>
        )
}

export default Comparison