import "./construction.css"
import building from "../assets/construction.png"

const Construction = () => {
    return (
        <div className="mt-5 text-center">
            <img src={building}/>
            <h3>Sitio en construcción</h3>
        </div>
    )
}

export default Construction