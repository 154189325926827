import { useNavigate } from "react-router-dom";
import parse from 'parse';
import { useEffect } from "react";
const options = [
    {
        title: "Códigos",
        path: "/codes"
    },
    {
        title: "Estudiantes",
        path: "/students"
    },
    {
        title: "Glosario",
        path: "/glossary"
    },
    {
        title: "Reportes",
        path: "/reports"
    },
]
const Menu = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if (!parse.User.current()) navigate("/admin")
    }, [])
    return (
        <>
            <div className="d-grid gap-2">
                {
                    options.map((option, index) => {
                        return <button className="btn btn-primary background-trulivers" type="button" key={index} onClick={()=> {
                            navigate(option.path)
                        }}>{option.title}</button>
                    })
                }
            </div>
        </>
    )
}

export default Menu