import { useEffect, useState } from "react"
import parse from 'parse'
import { useNavigate } from "react-router-dom";

const Students = () => {
    const navigate = useNavigate();
    const [codes, setCodes] = useState([])
    const [results, setResults] = useState([])
    const [currentCode, setCurrentCode] = useState()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (!parse.User.current()) navigate("/admin")
        if (codes.length === 0) getCodes()
        if (currentCode) getResults()
    }, [currentCode])

    const codeSelected = (e) => {
        setCurrentCode(e.target.value)
    }
    const getCodes = async () => {
        const result = await new parse.Query("Code").find()
        setCodes(result)
        setCurrentCode(result[0].get("code"))
    }
    const getResults = async () => {
        setLoading(true)
        const data = await new parse.Query("Result").equalTo("code", currentCode).notEqualTo("user", null).find()
        setResults(data)
        setLoading(false)
    }
    const resendEmail = async (id) => {
        const result = await parse.Cloud.run("resendResult", { code: id })
        alert("Correo enviado")
    }
    return (
        <div>
            <div className="input-group mb-3">
                <select className="form-select" aria-label="Default select example" onChange={codeSelected}>
                    {codes.map((code, index) => {
                        return <option value={code.get("code")} key={index}>{code.get("entity")}</option>
                    })}
                </select>
                <button className="btn btn-primary" type="button" id="refresh" onClick={getResults} disabled={loading} >{loading ? <div class="spinner-border text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div> : "Actualizar"}</button>
            </div>

            <table className="table mt-5">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombre</th>
                        <th scope="col" className="text-center">Encuesta</th>
                        {
                            //<th scope="col" className="text-center">Acudiente</th>
                        }
                        <th scope="col" className="text-center">Correo</th>
                    </tr>
                </thead>
                <tbody>
                    {results.map((result, index) => {
                        return <tr key={result.id}>
                            <td>{index + 1}</td>
                            <td>{result.get("user")?.name}</td>
                            <td className="text-center">{result.get("survey") ? "✔️" : "❌"}</td>
                            {
                                //<td className="text-center">{result.get("parent") ? "✔️" : "❌"}</td>
                            }
                            <td className="text-center" onClick={() => resendEmail(result.id)}>📧</td>
                        </tr>
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default Students