import { useNavigate } from 'react-router-dom';
import '../../utils/global.css'
import { useEffect, useState } from 'react';
import parse from 'parse';

const User = () => {
    const navigate = useNavigate();
    const [isDev, setIsDev] = useState(false)
    useEffect(() => {
        if (!localStorage.getItem("code")) navigate("/")
        parse.Config.get().then((config) => {
            if (config.get("devMode")) {
                setIsDev(true)
            }
        });
    }, []);
    const start = (event) => {
        event.preventDefault();
        const { name, typeId, id, phone, email, genre, age, economy, sisben, violence, ethnicity, parentName, parentEmail, parentPhone, school, grade } = event.target
        const user = {
            name: name.value,
            typeId: typeId.value,
            id: id.value,
            phone: phone.value,
            email: email.value,
            genre: genre.value,
            age: age.value,
            economy: economy.value,
            sisben: sisben.value,
            violence: violence.value,
            ethnicity: ethnicity.value,
            parentName: parentName.value,
            parentEmail: parentEmail.value,
            parentPhone: parentPhone.value,
            school: school.value,
            grade: grade.value
        }
        localStorage.setItem("user", JSON.stringify(user))
        navigate('/test')
    }

    return <>
        <h1 className="text-trulivers">Antes de empezar</h1>
        <p>Queremos saber acerca de ti, llena este formulario, por favor. </p>
        <form onSubmit={start}>
            <div className="mb-3">
                <label htmlFor="name" className="form-label">Nombre completo</label>
                <input type="text" className="form-control" id="name" required />
            </div>
            <div className="mb-3">
                <label htmlFor="typeId" className="form-label">Tipo de identificación</label>
                <select className="form-select" aria-label="TIpo de identificacion" id="typeId" required>
                    <option value="ti" defaultValue>Tarjeta de Identidad</option>
                    <option value="cc">Cédula de Ciudadanía</option>
                    <option value="na">Otra</option>
                </select>
            </div>
            <div className="mb-3">
                <label htmlFor="id" className="form-label">Número de identificación</label>
                <input type="number" className="form-control" id="id" required />
            </div>

            <div className="mb-3">
                <label htmlFor="phone" className="form-label">Teléfono</label>
                <input type="number" className="form-control" id="phone" required />
            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">Correo Electrónico</label>
                <input type="email" className="form-control" id="email" required />
            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">Género</label>
                <div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="genre" value="M" required />
                        <label className="form-check-label" htmlFor="genre">Masculino</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="genre" value="F" />
                        <label className="form-check-label" htmlFor="genre">Femenino</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="genre" value="O" />
                        <label className="form-check-label" htmlFor="genre">Otro</label>
                    </div>
                </div>

            </div>

            <div className="mb-3">
                <label htmlFor="age" className="form-label">Edad</label>
                <input type="number" className="form-control" id="age" required />
            </div>

            <div className="mb-3">
                <label className="form-label">Colegio</label>
                <input type="text" className='form-control' name='school' required />
            </div>

            <div className="mb-3">
                <label htmlFor="grade" className="form-label">Grado escolar</label>
                <input type="number" className="form-control" id="grade" required />
            </div>

            <div className="mb-3">
                <label htmlFor="economy" className="form-label">Dependencia Económica</label>
                <select className="form-select" aria-label="Dependencia económica" id="economy" required>
                    <option value="Padre y Madre" defaultValue>Padre y Madre</option>
                    <option value="Padre">Padre</option>
                    <option value="Madre">Madre</option>
                    <option value="Familiares">Familiares</option>
                    <option value="Otro">Otro</option>
                </select>
            </div>

            <div className="mb-3">
                <label htmlFor="email" className="form-label">Grupo de SISBEN</label>
                <div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="sisben" value="A" required />
                        <label className="form-check-label">A</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="sisben" value="B" />
                        <label className="form-check-label">B</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="sisben" value="C" />
                        <label className="form-check-label">C</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="sisben" value="D" />
                        <label className="form-check-label">D</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="sisben" value="Ninguno" checked />
                        <label className="form-check-label">No tengo SISBEN</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="sisben" value="Nose" />
                        <label className="form-check-label">Tengo, pero no lo sé</label>
                    </div>
                </div>

            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">Grupo étnico</label>
                <div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="ethnicity" value="ninguno" required checked onChange={e => { }} />
                        <label className="form-check-label">Ninguno</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="ethnicity" value="indígenas" />
                        <label className="form-check-label">Indígenas</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="ethnicity" value="afrocolombianos" />
                        <label className="form-check-label">Afrocolombianos</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="ethnicity" value="raizales" />
                        <label className="form-check-label">Raizales</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="ethnicity" value="palenqueros" />
                        <label className="form-check-label">Palenqueros</label>
                    </div>
                </div>

            </div>
            <div className="mb-3">
                <label htmlFor="email" className="form-label">¿Eres desplazado por la violencia?</label>
                <div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="violence" value="No" required checked onChange={e => { }} />
                        <label className="form-check-label">No</label>
                    </div>
                    <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="violence" value="Si" />
                        <label className="form-check-label">Si</label>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <label htmlFor="parentName" className="form-label">Nombre del acudiente</label>
                <input type="text" className="form-control" id="parentName" required />
            </div>
            <div className="mb-3">
                <label htmlFor="parentEmail" className="form-label">Correo electrónico del acudiente</label>
                <input type="email" className="form-control" id="parentEmail" required />
            </div>
            <div className="mb-3">
                <label htmlFor="parentPhone" className="form-label">Teléfono del acudiente</label>
                <input type="number" className="form-control" id="parentPhone" required />
            </div>
            <div className="form-check">
                <input className="form-check-input" type="checkbox" value="" id="datausage" required/>
                    <label className="form-check-label" htmlFor="datausage">
                    Autorizo el uso y tratamiento de mis datos. <a href="https://drive.google.com/file/d/1bSup8_LggCE_Y9IXwjKCR5IPr6Rtx70q/view?usp=sharing" target="_blank">Leer documento</a>
                    </label>
            </div>
            <div className="row justify-content-center mt-4 mb-5">
                <div className="col-auto">
                    {isDev ?
                        <button className="btn background-trulivers btn-primary" onClick={() => {
                            start({
                                preventDefault: () => { }, target: {
                                    name: { value: "Sample" },
                                    typeId: { value: "cc" },
                                    id: { value: 1122334455 },
                                    phone: { value: 3214567890 },
                                    email: { value: "luimi@live.com" },
                                    genre: { value: "M" },
                                    age: { value: 37 },
                                    economy: { value: "Otro" },
                                    sisben: { value: "Ninguno" },
                                    violence: { value: "No" },
                                    ethnicity: { value: "ninguno" },
                                    parentName: { value: "Sample" },
                                    parentEmail: { value: "sample@sample.com" },
                                    parentPhone: { value: 3214567899 }
                                }
                            })
                        }}>Iniciar prueba</button> :
                        <button type="submit" className="btn background-trulivers btn-primary" >Iniciar prueba</button>
                    }

                </div>
            </div>
        </form>

    </>
}

export default User