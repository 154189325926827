import { useEffect, useState } from "react";
import parse from 'parse';
import { getNewCodeObject } from "../../utils/parseController";
import { useNavigate } from "react-router-dom";

const Codes = () => {

    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        entity: '',
        left: '',
        code: ''
    });
    const [current, setCurrent] = useState(getNewCodeObject())
    const [codes, setCodes] = useState([])
    const [filter, setFilter] = useState("")

    useEffect(() => {
        if(parse.User.current())
            getCodes()
        else 
            navigate("/admin")

    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        current.set(name, name === "left" ? Number(value) : value)
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const exist = await new parse.Query("Code").equalTo("code", current.get("code")).first()
        if(exist && current.isNew()) {
            alert("Ya existe un codigo igual")
            return
        }
        await current.save()
        setFormData({ entity: '', left: '', code: '' });
        setCurrent(getNewCodeObject())
        await getCodes()
        alert("Código guardado")
    };

    const getCodes = async () => {
        setCodes(await new parse.Query("Code").descending("createdAt").find())
    }
    const randomString = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }
    const filteredCodes = () => {
        return codes.filter((o) => {
            return o.get("entity").toLowerCase().includes(filter.toLowerCase()) 
            || o.get("code").toLowerCase().includes(filter.toLowerCase())
        })
    }
    return <div>
        <div className="row justify-content-end mt-4 mb-3">
            <div className="col-auto">
                <button type="button" className="btn background-trulivers btn-primary" onClick={() => {
                    setFormData({ entity: '', left: '', code: ''});
                    setCurrent(getNewCodeObject())
                }}>Nuevo Código</button>
            </div>
        </div>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <input type="text" className="form-control" name="entity" placeholder="Entidad" required value={formData.entity} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
                <input type="number" className="form-control" name="left" placeholder="Cantidad" required value={formData.left} onChange={handleInputChange} />
            </div>
            <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Código" aria-label="codigo" aria-describedby="codigo-aria" required name="code" value={formData.code} onChange={handleInputChange} />
                <span className="input-group-text" id="codigo-aria" onClick={() => {
                    const code = randomString(5)
                    setFormData({ ...formData, "code": code });
                    current.set("code", code)
                }}>Generar</span>
            </div>
            <button type="submit" className="btn background-trulivers btn-primary">Guardar</button>
        </form>
        <h1 className="mt-5">Códigos</h1>
        <div className="mb-3">
                <input type="text" className="form-control" name="filter" placeholder="Filtrar" required value={filter} onChange={(e) => {
                    setFilter(e.target.value)
                }} />
            </div>
        <ol className="list-group list-group-numbered mt-3 mb-5">
            {filteredCodes().map((code, index) => {
                return <li className="list-group-item d-flex justify-content-between align-items-start" onClick={() => {
                    setCurrent(code)
                    setFormData({ entity: code.get("entity"), left: code.get("left"), code: code.get("code") });
                }} key={index}>
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">{code.get("entity")}</div>
                        {code.get("code")}
                    </div>
                    <span className="badge bg-primary rounded-pill">{code.get("left")}</span>
                </li>
            })}

        </ol>

    </div>
}

export default Codes
