
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/layout/layout";
import Rules from "./pages/rules/rules";
import Test from "./pages/test/Test";
import Result from "./pages/result/Result";
import User from "./pages/user/User";
import Main from "./pages/main/main";
import parse from 'parse';
import Admin from "./pages/admin/Admin";
import Codes from "./pages/codes/Codes";
import Code from "./pages/code/Code";
import Areas from "./pages/areas/areas";
import Area from "./pages/area/area";
import Comparison from "./pages/comparison/comparison";
import Search from "./pages/search/search";
import Parent from "./pages/parent/Parent";
import Menu from "./pages/admin/Menu";
import Students from "./pages/students/Students";
import Glossary from "./pages/glossary/Glossary";
import Reports from "./pages/reports/Reports";
import Ia from "./pages/ia/Ia";
import Survey from "./pages/survey/Survey";
import SedSurvey from "./pages/sedsurvey/SedSurvey";
import End from "./pages/end/End";

function App() {
  parse.initialize("hgSrGX8QvZYqJa2S7vsIbkEREPg7RyrA6LZF3jOE", "BMmJOnxkLoul0tPtkpVjTObkPpSagMXY1theCFQ9");
  parse.serverURL = 'https://parseapi.back4app.com'
  //parse.serverURL = 'http://localhost:1337/parse'
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Main />} />
          <Route path="/:id" element={<Main />} />
          <Route path="/rules" element={<Rules />} />
          <Route path="/user" element={<User />} />
          <Route path="/test" element={<Test />} />
          <Route path="/result" element={<Result />} />
          <Route path="/admin" element={<Admin />} />
          <Route path="/codes" element={<Codes />} />
          <Route path="/code/:code" element={<Code />} />
          <Route path="/areas" element={<Areas />} />
          <Route path="/area/:id" element={<Area />} />
          <Route path="/comparison/:id" element={<Comparison />} />
          <Route path="/search" element={<Search />} />
          <Route path="/parent/:id" element={<Parent />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/students" element={<Students />} />
          <Route path="/glossary" element={<Glossary />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/ia" element={<Ia />} />
          <Route path="/survey" element={<Survey />} />
          <Route path="/sedsurvey" element={<SedSurvey />} />
          <Route path="/end" element={<End />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
