import { useState, useEffect } from "react"
import { getQuestions as _getQuestions } from '../../utils/tav'
import { useNavigate } from 'react-router-dom';
import StatusLoading from "../../components/StatusLoading/StatusLoading"
import StatusError from "../../components/StatusError/StatusError"
import parse from 'parse'
import '../../utils/global.css'


const Test = () => {
    const [questions, setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [answers, setAnswers] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [time, setTime] = useState(Date.now())
    const navigate = useNavigate();
    const options = [
        { text: 'En nada me define', value: 1 },
        { text: 'Me define muy poco', value: 2 },
        { text: 'Me define en cierta medida', value: 3 },
        { text: 'Me define en gran medida', value: 4 },
        { text: 'Me define completamente', value: 5 },
    ];

    useEffect(() => {
        if (!localStorage.getItem("user")) navigate('/user')
        if (!isLoading) getQuestions();
        
    }, []);
    const getQuestions = () => {
        setLoading(true)
        _getQuestions((data) => {
            setQuestions(data);
            parse.Config.get().then((config) => {
                if (config.get("devMode")) {
                    setCurrentQuestion(70)
                    fillAnswers(data)
                }
            });
            setLoading(false);
        }, (error) => {
            setError(error);
            setLoading(false);
        })
    };
    const sendAnswers = () => {
        localStorage.setItem("answers", JSON.stringify(answers))
        localStorage.setItem("time", Date.now() - time)
        navigate('/result')
    }
    const nextQuestion = () => {
        if (
            currentQuestion < questions.length - 1 &&
            answers[currentQuestion]
        ) {
            setCurrentQuestion(currentQuestion + 1);
        }
        if (
            currentQuestion === questions.length - 1 &&
            answers[currentQuestion]
        ) {
            sendAnswers();
        }
    }
    const fillAnswers = (_questions) => {
        let _answers = [];
        _questions.forEach((question) => {
            _answers.push({ id: question.id, response: 5 });
        });
        setAnswers(_answers);
    };
    if (isLoading) return <StatusLoading />
    else if (error) return <StatusError error={error} />
    else if (questions) return <div className="text-center">
        <h2>{currentQuestion + 1}. {questions[currentQuestion]?.text}</h2>
        <div className="d-grid gap-2 mt-5">
            {options.map((option) => {
                let status =
                    answers[currentQuestion] &&
                    answers[currentQuestion].response === option.value;
                return <button className={`btn ${status ? 'btn-primary background-trulivers' : 'btn-light'}`} key={option.value} type="button" onClick={() => {
                    let _answers = [...answers];
                    _answers[currentQuestion] = {
                        id: currentQuestion + 1,
                        response: option.value,
                    };
                    setAnswers(_answers);
                }}>{option.text}</button>
            })}
        </div>
        <div className="container text-center mt-5">
            <div className="row align-items-start">
                <div className="col d-grid gap-2">
                    <button className="btn btn-primary background-trulivers" onClick={() => {
                        if (currentQuestion > 0) setCurrentQuestion(currentQuestion - 1);
                    }}>Volver</button>
                </div>
                <div className="col">

                </div>
                <div className="col d-grid gap-2">
                    <button className="btn btn-primary background-trulivers" onClick={nextQuestion}>
                        {currentQuestion === questions.length - 1 ? 'Finalizar' : 'Continuar'}
                    </button>
                </div>
            </div>
        </div>
    </div>
}

export default Test