import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import parse from 'parse'
import '../../utils/global.css'

const Main = () => {
    const params = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState()
    const [code, setCode] = useState("")
    useEffect(() => {
        if (params.id) setCode(params.id)
        checkPreviousInputs()
    }, [])
    const verify = async (e) => {
        e.preventDefault()
        const { code } = e.target;

        const result = await new parse.Query("Code").equalTo("code", code.value).first()
        if (result && result.get('left') > 0) {
            localStorage.clear();
            localStorage.setItem("code", code.value)
            navigate("/rules")
        } else if (!result) _setError("Código invalido")
        else if (result.get('left') <= 0) _setError("Este código ya no puede realizar más pruebas, contáctanos para obtener más")
    }
    const checkPreviousInputs = () => {
        if (localStorage.getItem("user")) {
            navigate("/test")
        } else if (localStorage.getItem("code")) {
            navigate("/user")
        } else if (localStorage.getItem("survey")) {
            navigate("/sedsurvey")
        } else if (localStorage.getItem("resultId")) {
            navigate("/result")
        }
    }
    const _setError = (message) => {
        setError(<p className='text-danger'>{message}</p>)
    }
    return <form className="text-center" onSubmit={verify}>
        <h1 className='text-trulivers'>Ingrese el código de ingreso</h1>
        <div className="mb-3 input-group-lg">
            <label htmlFor="code" className="form-label">Código</label>
            <input className="form-control text-center" id="code" onChange={(e) => setCode(e.target.value)} value={code} />
        </div>
        {error}
        <button type="submit" className="btn btn-primary mb-3 background-trulivers">Verificar</button>
    </form>
}

export default Main