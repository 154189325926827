import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Spinner from '../../utils/spinner';
import parse from 'parse'
import '../../utils/global.css'

const Area = () => {
    const params = useParams();
    const [careers, setCareers] = useState([])
    const [area, setArea] = useState()
    useEffect(() => {
        if(!area) getArea()
        if(area && careers.length === 0) getCareers()
    }, [area])
    const getCareers = async () => {
        let result = await new parse.Query("Career").equalTo("area", area).ascending("name").find()
        setCareers(result)
    }
    const getArea = async () => {
        let result = await new parse.Query("Area").get(params.id)
        setArea(result)
        return
    }
    if(!area) {
        return <Spinner />
    } else
    return (
        <div className="pb-5">
            <ul className="list-group">
                <li className="list-group-item active background-trulivers" aria-current="true"><b>{area?area.get("name"):""}</b></li>
                {careers.map((career, index) => {
                    return <li className="list-group-item" key={index}>
                        <Link to={`/comparison/${career.id}`}>
                            {career.get("name")}
                        </Link>
                    </li>
                })}
            </ul>

        </div>
    )
}

export default Area