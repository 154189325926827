import { useEffect, useState } from 'react'
import '../../utils/global.css'
import parse from 'parse'
import { checkUserIsAdmin } from '../../utils/parseController'
import { useNavigate } from 'react-router-dom'

const Admin = () => {
    const [error, setError] = useState()
    const navigate = useNavigate();
    useEffect(() => {
        if(parse.User.current()) goToMenu()
    }, [])
    const login = async (e) => {
        e.preventDefault()
        const { user, pass } = e.target
        let _user
        try {
            _user = await parse.User.logIn(user.value, pass.value)
        } catch (e) { }
        if (!_user) {
            setError("Usuario y/o contraseña invalidos")
            return
        }
        goToMenu()
    }
    const goToMenu = async() => {
        const isAdmin = await checkUserIsAdmin()
        if(isAdmin) navigate("/menu")
        else setError("Este usuario no es administrador")
    }
    const showError = () => {
        if (!error) return <></>
        else return <p className='text-danger'>{error}</p>
    }
    return <div className="text-center">
        <p>Ingresa el usuario y la contraseña de administracion</p>
        <form onSubmit={login}>
            <div className="mb-3">
                <label htmlFor="user" className="form-label">Usuario</label>
                <input type="text" className="form-control" id="user" required />
            </div>
            <div className="mb-3">
                <label htmlFor="pass" className="form-label">Contraseña</label>
                <input type="password" className="form-control" id="pass" required />
            </div>
            {showError()}
            <div className="row justify-content-center mt-4 mb-5">
                <div className="col-auto">
                    <button type="submit" className="btn background-trulivers btn-primary">Entrar</button>
                </div>
            </div>
        </form>
        <hr />
        <p>Reiniciar todo</p>
        <button className='btn btn-primary background-trulivers' onClick={() => {
            localStorage.clear();
            navigate("/")
        }}>Reset</button>
    </div>
}

export default Admin