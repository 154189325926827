import React, { useState } from 'react';
import { getFieldsData, SurveyFields } from '../../components/SurveyFields/SurveyFields';
import Markdown from 'react-markdown';
import parse from 'parse'
import StatusLoading from '../../components/StatusLoading/StatusLoading';
import { useNavigate } from 'react-router-dom';

const questions = [
    {
        type: "text",
        title: "¿Qué actividades disfrutas hacer en tu tiempo libre?",
        hint: "Ej: leer, dibujar, jugar deportes, resolver problemas matemáticos, interactuar con otras personas, etc.",
        id: "q1"
    },
    {
        type: "text",
        title: "¿En qué materias te destacas o te resultan más fáciles en la escuela?",
        hint: "Ej: matemáticas, ciencias, lenguaje, historia, arte, etc.",
        id: "q2"
    },
    {
        type: "text",
        title: "¿Qué tipo de trabajos te llaman la atención?",
        hint: "Ej: científico, escritor, artista, ingeniero, profesor, etc.",
        id: "q3"
    },
    {
        type: "text",
        title: "¿Cuáles son tus fortalezas?",
        hint: "Ej: creatividad, organización, liderazgo, paciencia, comunicación, etc.",
        id: "q4"
    },
    {
        type: "text",
        title: "¿Cuáles son tus debilidades?",
        hint: "Ej: timidez, impaciencia, dificultad para concentrarte, etc.",
        id: "q5"
    },
    {
        type: "text",
        title: "¿Qué tipo de ambiente laboral te gustaría?",
        hint: "Ej: trabajar en equipo, trabajar solo, un ambiente creativo, un ambiente estructurado, etc.",
        id: "q6"
    },
    {
        type: "text",
        title: "¿Qué valores son importantes para ti en un trabajo?",
        hint: "Ej: estabilidad, crecimiento personal, ayudar a los demás, etc.",
        id: "q7"
    },
    {
        type: "text",
        title: "¿Qué te motiva a alcanzar tus metas?",
        hint: "Ej: reconocimiento, dinero, ayudar a los demás, etc.",
        id: "q8"
    },
    {
        type: "text",
        title: "¿Qué tipo de desafíos te gustan enfrentar?",
        hint: "Ej: resolver problemas complejos, aprender cosas nuevas, liderar proyectos, etc.",
        id: "q9"
    },
    {
        type: "text",
        title: "¿Qué te frustra o te aburre en una tarea?",
        hint: "Ej: tareas repetitivas, trabajar bajo presión, no tener autonomía, etc.",
        id: "q10"
    },
]

const Ia = () => {
    const [result, setResult] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const sendToIA = async (evt) => {
        evt.preventDefault()
        setLoading(true)
        let answers = getFieldsData(evt, questions)
        const prompt = `Con base en estas preguntas y respuestas, ¿qué carreras me recomiendas estudiar? \n ${questions.map((question) => {
            return `\n ${question.title}\nRespuesta: ${answers[question.id]}`
        })}`
        const _result = await parse.Cloud.run("getAiResult", { prompt })
        if (_result) {
            setResult(_result)
            tryRegister(answers, _result)
        }
        setLoading(false)
    }
    const tryRegister = async (answers, results) => {
        let id = localStorage.getItem("resultId")
        if (!id) return

        await parse.Cloud.run("setIAResult", { id, answers, results })
    }
    const questionsView = () => {
        return <form className="text-start mt-5 mb-5" onSubmit={sendToIA}>
            <p className="text-trulivers text-center">Responde estas preguntas, así nuestra IA podrá analizar tus respuestas y sugerirte alguna carrera.</p>
            <SurveyFields questions={questions} />
            <button type="submit" className="btn background-trulivers btn-primary">Enviar respuestas</button>
        </form>
    }
    const resultView = () => {
        return <div>
            <Markdown children={result} />
            {localStorage.getItem("resultId") !== undefined? <div class="d-grid gap-2 d-md-flex justify-content-md-end mb-5">
                <button className="btn btn-primary background-trulivers" type="button" onClick={() => navigate("/survey")}>Continuar</button>
            </div>: ""}
        </div>
    }
    return <div>
        {!result && !loading ? questionsView() : ""}
        {result && !loading ? resultView() : ""}
        {loading ? <StatusLoading /> : ""}
    </div>
};

export default Ia;