import { Link, useNavigate } from "react-router-dom";
import CardIconInfo from "../../components/CardIconInfo/CardIconInfo"
import book from '../../assets/book.png'
import chat from '../../assets/chat.png'
import person from '../../assets/person.png'
import '../../utils/global.css'
import { useEffect } from "react";

const Rules = () => {
    const navigate = useNavigate();
    useEffect(() => {
        if(!localStorage.getItem("code")) navigate("/")
    }, []);
    return (
        <>
            <h1 className="text-trulivers">¡Hola!</h1>
            <p>Bienvenido/a al test vocacional desarrollado por <b className="text-trulivers">Trulivers</b></p>
            <p>El Test de Carrera es un cuestionario de 15 minutos que relaciona sus
                intereses, ambiciones, antecedentes, personalidad y preferencias
                laborales con probables ocupaciones utilizando un potente aprendizaje
                automático.</p>
            <p>Una vez que haya completado la encuesta, los resultados le
                proporcionarán una visión general de los tipos de ocupaciones que
                encajan con su personalidad e intereses, así como de las disciplinas
                concretas que debes investigar.</p>
            <p>Para el test no hay respuestas buenas ni malas, y las recomendaciones no son definitivas, es decir, son solo orientaciones que puedes tener en cuenta para explorar con mucho cuidado tus opciones de estudios en educación superior.</p>
            <CardIconInfo icon={book} text="Asegúrese de estar en un entorno cómodo en el que pueda concentrarse en esta prueba de 15 minutos" />
            <CardIconInfo icon={chat} text="Responderá a 72 preguntas sobre usted. No hay respuestas incorrectas así que sea libre de elegir cualquier respuesta." />
            <CardIconInfo icon={person} text="Vea su Informe de Carrera completo en línea. Descubra los puntos fuertes de su personalidad laboral y su lista de carreras." />
            <div className="row justify-content-center mt-4 mb-5">
                <div className="col-auto">
                    <Link to="/user">
                        <button type="button" className="btn background-trulivers btn-primary">Iniciar prueba</button>
                    </Link>

                </div>
            </div>
        </>
    )
}

export default Rules