import { useState } from "react"
import Spinner from "../../utils/spinner"
import { Link } from "react-router-dom"
import parse from 'parse'

const Search = () => {
    const [search, setSearch] = useState("")
    const [loading, setLoading] = useState(false)
    const [careers, setCareers] = useState([])
    const showLoading = () => {
        if(loading) return <Spinner/>
        else return <></>
    }
    const showList = () => {
        if(!careers) return <></>
        else return <div className="mb-5">
            <ul className="list-group">
                {careers.map((career, index) => {
                    return <li className="list-group-item" key={index}>
                        <Link to={`/comparison/${career.id}`}>
                            {career.get("name")}
                        </Link>
                    </li>
                })}
            </ul>
        </div>
    }
    const getSearch = async () => {
        if(search.trim()==="") return
        let result = await new parse.Query("Career").contains("names", search.toUpperCase().trim()).find()
        setCareers(result)
    }
    return (
        <>
            <div className="input-group mb-3 mt-3">
                <input type="text" className="form-control" placeholder="Escribe el nombre de la carrera" aria-label="topic" aria-describedby="lupa" value={search} onChange={(e) => setSearch(e.target.value)} onKeyDown={(e) => {
                    if(e.key === 'Enter') getSearch()
                }}/>
                <button className="btn btn-outline-secondary" id="lupa" onClick={getSearch}>🔍</button>
            </div>
            {showLoading()}
            {showList()}
        </>
    )
}

export default Search