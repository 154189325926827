import logo from '../../assets/logo_circle_blue.png'
import './StatusError.css'

const StatusError = ({error}) => {
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-auto'>
                    <img className="logo" src={logo} alt="logo"/>
                </div>
            </div>
            <div className='row justify-content-center mt-4'>
                <div className='col-auto text'>
                    {JSON.stringify(error)}
                </div>
            </div>
        </>

    )
}

export default StatusError