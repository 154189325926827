import React from 'react';
import { useNavigate } from 'react-router-dom';

const SedSurvey = () => {
    const navigate = useNavigate()
    return <div className='text-center'>
        <h1 className="text-trulivers">Encuesta Secretaría de Educación</h1>
        <iframe title="SED Survey" src="https://docs.google.com/forms/d/e/1FAIpQLScU3i-u_Gs6h2Zm7R6dorfCaz-BXg9BTovUtc5TJ8Z3OI4HmQ/viewform" style={{width: "100%", height: "80vh"}}/>
        <button className={`btn mt-5 mb-5 btn-primary background-trulivers`} onClick={() => {
            window.open("https://docs.google.com/forms/d/e/1FAIpQLScU3i-u_Gs6h2Zm7R6dorfCaz-BXg9BTovUtc5TJ8Z3OI4HmQ/viewform", '_blank', 'noopener,noreferrer')
        }}>Abrir encuesta otra pestaña</button>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end mb-5">
            <button className="btn btn-primary background-trulivers" type="button" onClick={() => {
                navigate("/end")
            }}>Continuar</button>
        </div>
    </div>;
};

export default SedSurvey;