import parse from 'parse';

export const getAdminReadACL = () => {
    const acl = new parse.ACL()
    acl.setPublicReadAccess(false)
    acl.setRoleReadAccess("Admin", true)
    acl.setRoleWriteAccess("Admin", true)
    return acl
}

export const checkUserIsAdmin = async () => {
    const role = await new parse.Query(parse.Role).equalTo("name", "Admin").first()
    const relation = new parse.Relation(role, 'users')
    const result = await relation.query().equalTo('objectId', parse.User.current().id).first()
    return result !== undefined
}

export const getNewCodeObject = () => {
    const Code = parse.Object.extend("Code")
    const acl = new parse.ACL()
    acl.setPublicReadAccess(true)
    acl.setRoleReadAccess("Admin", true)
    acl.setRoleWriteAccess("Admin", true)
    const code = new Code()
    code.setACL(acl)
    return code
}

export const getNewGlossaryObject = () => {
    const Code = parse.Object.extend("Glossary")
    const acl = new parse.ACL()
    acl.setPublicReadAccess(true)
    acl.setRoleReadAccess("Admin", true)
    acl.setRoleWriteAccess("Admin", true)
    const code = new Code()
    code.setACL(acl)
    return code
}