import parse from 'parse'
import { useEffect, useState } from 'react'
const Programs = ({ career }) => {
    const [programs, setPrograms] = useState([])
    const [filter, setFilter] = useState("")

    useEffect(() => {
        getPrograms()
    }, [])
    const getPrograms = async () => {
        let result = await new parse.Query("Program").equalTo("career", career).include("institute").include("municipali").include("department").limit(999).find()
        setPrograms(result)
    }
    return (
        <div>
            <div className="input-group mb-3 mt-3">
                <span className="input-group-text" id="lupa">🔍</span>
                <input type="text" className="form-control" placeholder="Filtrar" aria-label="topic" aria-describedby="lupa" value={filter} onChange={(e) => setFilter(e.target.value)} />
            </div>
            {programs.filter((program) => {
                return program.get("name").toLowerCase().includes(filter.toLowerCase()) ||
                    program.get("institute").get("name").toLowerCase().includes(filter.toLowerCase()) ||
                    program.get("municipali").get("name").toLowerCase().includes(filter.toLowerCase()) ||
                    program.get("formationLvl").toLowerCase().includes(filter.toLowerCase())
            }).map((program, index) => {
                return <div className="card" key={index}>
                    <div className="card-body">
                        <h5 className="card-title">{program.get("institute").get("name")}</h5>
                        <h6 className="card-subtitle mb-2 text-body-secondary">{program.get("name")}{program.get("recognition") !== "n/a" ? ` - ${program.get("recognition")}` : ""}</h6>
                        <p className="card-text">{program.get("periodType")} <span className="badge text-bg-secondary">{program.get("period")}</span> <span className='card-text badge text-bg-secondary'>{program.get("cost") ? `$${program.get("cost").toLocaleString()}` : ""}</span></p>

                        <blockquote className="blockquote mb-0">
                            <footer className="blockquote-footer">{program.get("formationLvl")}</footer>
                        </blockquote>
                        <p className="card-text"><small className="text-body-secondary text-right">{program.get("municipali").get("name")} - {program.get("department").get("name")}</small></p>
                    </div>
                </div>
            })}
        </div>
    )
}

export default Programs