import { useEffect, useState } from "react"
import parse from 'parse'

const ModalGlossary = ({close}) => {
    const sample = [
        { title: "Ejemplo", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quibusdam aut fugit molestias cupiditate aspernatur, unde amet minus dicta id asperiores, mollitia consequuntur vitae quis iusto? Et ut incidunt perferendis." },
        { title: "Ejemplo2", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quibusdam aut fugit molestias cupiditate aspernatur, unde amet minus dicta id asperiores, mollitia consequuntur vitae quis iusto? Et ut incidunt perferendis." },
        { title: "Ejemplo3", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quibusdam aut fugit molestias cupiditate aspernatur, unde amet minus dicta id asperiores, mollitia consequuntur vitae quis iusto? Et ut incidunt perferendis." },
        { title: "Ejemplo4", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quibusdam aut fugit molestias cupiditate aspernatur, unde amet minus dicta id asperiores, mollitia consequuntur vitae quis iusto? Et ut incidunt perferendis." },
        { title: "Ejemplo5", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quibusdam aut fugit molestias cupiditate aspernatur, unde amet minus dicta id asperiores, mollitia consequuntur vitae quis iusto? Et ut incidunt perferendis." },
        { title: "Ejemplo6", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quibusdam aut fugit molestias cupiditate aspernatur, unde amet minus dicta id asperiores, mollitia consequuntur vitae quis iusto? Et ut incidunt perferendis." },
        { title: "Ejemplo7", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quibusdam aut fugit molestias cupiditate aspernatur, unde amet minus dicta id asperiores, mollitia consequuntur vitae quis iusto? Et ut incidunt perferendis." },
        { title: "Ejemplo8", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quibusdam aut fugit molestias cupiditate aspernatur, unde amet minus dicta id asperiores, mollitia consequuntur vitae quis iusto? Et ut incidunt perferendis." },
        { title: "Ejemplo9", description: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi quibusdam aut fugit molestias cupiditate aspernatur, unde amet minus dicta id asperiores, mollitia consequuntur vitae quis iusto? Et ut incidunt perferendis." },
    ]
    const [glossary, setGlossary] = useState([])
    const [filter, setFilter] = useState("")

    useEffect(() => {
        getGlossary()
    }, [])

    const getGlossary = async () => {
        let result = await new parse.Query("Glossary").limit(9999).find()
        setGlossary(result)
    }
    return (
        <div className="card">
            <div className="card-header">
                <div className="row">
                    <span className="col align-middle">
                        Glosario
                    </span>
                    <button className="col-auto btn btn-light" onClick={close}>❌</button>
                </div>
            </div>
            <div className="card-body">
                <div className="input-group mb-3">
                    <span className="input-group-text" id="lupa">🔍</span>
                    <input type="text" className="form-control" placeholder="Filtrar" aria-label="topic" aria-describedby="lupa" value={filter} onChange={(e) => setFilter(e.target.value)} />
                </div>
                <div className="list-group">
                    {glossary.filter((term) => term.get("title").includes(filter)).map((term, index) => {
                        return <a className="list-group-item list-group-item-action" aria-current="true" key={index}>
                            <p className="mb-1"><b>{term.get("title")}</b></p>
                            <small>{term.get("description")}</small>
                        </a>
                    })}
                </div>
            </div>
        </div>
    )
}

export default ModalGlossary