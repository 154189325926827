import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { joinAreasIds } from '../../utils/tav';

const End = () => {
    const [result, setResult] = useState([])
    useEffect(() => {
        if(result.length === 0) getResult()
    }, [])

    const getResult = async () => {
        setResult(await joinAreasIds(JSON.parse(localStorage.getItem("result"))) )
    }
    const navigate = useNavigate()
    return <div className='text-center'>
        <h1 className="text-trulivers">¡Gracias por usar nuestra App!</h1>
        <p>¡Síguenos en nuestro Instagram!</p>
        <a href="https://www.instagram.com/trulivers.app/" target="_blank">
            <img className="icon" src="https://cdn-icons-png.flaticon.com/512/3955/3955027.png"/>
        </a>
        <hr />
        <p>Busca entre todas las carreras disponibles en tu localidad, compara instituciones, precio y calidad.</p>
        <div className="d-grid gap-2 mb-5">
            {
                result?.map((option, index) => {
                    return <button key={`result-${index}`} className="btn btn-primary background-trulivers" onClick={() => {
                        navigate(`/area/${option.id}`)
                    }}>
                        {option.title}
                    </button>
                })
            }

        </div>
        <button className="btn btn-primary background-trulivers" onClick={() => {
            navigate(`/search`)
        }}>Buscar carreras por nombre</button>
        <hr/>
        <button className='btn btn-primary background-trulivers mt-5' onClick={() => {
            localStorage.clear();
            navigate("/")
        }}>Finalizar</button>
    </div>;
};

export default End;