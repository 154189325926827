import logo from '../../assets/logo_circle_blue.png'
import './StatusLoading.css'

const StatusLoading = () => {
    return (
        <>
            <div className='row justify-content-center'>
                <div className='col-auto'>
                    <img className="logo" src={logo} alt="logo"/>
                </div>
            </div>
            <div className='row justify-content-center mt-4'>
                <div className='col-auto'>
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </>

    )
}

export default StatusLoading