import { useState } from "react";
import { SurveyFields, getFieldsData } from "../../components/SurveyFields/SurveyFields";
import { useParams } from "react-router-dom";
import parse from 'parse';

const questions = [
    {
        type: "text",
        title: "Nombre",
        id: "question1"
    },
    {
        type: "text",
        title: "Cédula",
        id: "question2"
    },
    {
        type: "text",
        title: "Teléfono",
        id: "question3"
    },
    {
        type: "text",
        title: "Correo electrónico",
        id: "question4"
    },
    {
        type: "text",
        title: "Dirección",
        id: "question5"
    },
    {
        type: "radio",
        title: "Ingresos mensuales",
        id: "question6",
        options: ["de 1 a 2 SMLV", "de 2 a 3 SMLV", "de 3 a 4 SMLV", "de 4 a 5 SMLV", "mas de 5 SMLV"]
    },
    {
        type: "radio",
        title: "Egresos mensuales",
        id: "question7",
        options: ["de 1 a 2 SMLV", "de 2 a 3 SMLV", "de 3 a 4 SMLV", "de 4 a 5 SMLV", "mas de 5 SMLV"]
    },
    {
        type: "text",
        title: "Tiempo laborado en la ultima empresa",
        hint: "Ej. 5 meses, 2 años",
        id: "question8"
    },
]
const Parent = () => {
    const params = useParams();
    const [surveyDone, setSurveyDone] = useState(false)
    const sendSurvey = async (e) => {
        e.preventDefault()
        await parse.Cloud.run("setParent", { id: params.id, survey: getFieldsData(e, questions) })
        setSurveyDone(true)
    }
    if (!surveyDone)
        return (
            <div>
                <form className="mt-3 mb-5" onSubmit={sendSurvey}>
                    <h1 className="text-trulivers text-center mb-5">Encuesta perfil acudiente</h1>
                    <SurveyFields questions={questions} />
                    <button type="submit" className="btn background-trulivers btn-primary">Enviar</button>
                </form>
            </div>
        )
    if (surveyDone)
        return (
            <div className="mt-5 text-center">
                <span style={{ fontSize: 'xxx-large' }}>✅</span><br /><h1 className="text-trulivers">Gracias por tu apoyo</h1>
            </div>
        )
}

export default Parent