import { useEffect, useState } from "react";
import parse from 'parse';
import { getNewCodeObject, getNewGlossaryObject } from "../../utils/parseController";
import { useNavigate } from "react-router-dom";

const emptyGlossary = { title: "", description: "" }

const Glossary = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({ ...emptyGlossary });
    const [current, setCurrent] = useState()
    const [glossary, setGlossary] = useState([])
    const [filter, setFilter] = useState("")

    useEffect(() => {
        if (parse.User.current())
            getGlossary()
        else
            navigate("/admin")

    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const obj = current ? current : getNewGlossaryObject()
        Object.keys(formData).forEach((key) => {
            obj.set(key, formData[key])
        })
        await obj.save()
        setFormData({ ...emptyGlossary });
        setCurrent(getNewCodeObject())
        await getGlossary()
        alert("Término guardado")
    };

    const getGlossary = async () => {
        setGlossary(await new parse.Query("Glossary").ascending("title").find())
    }

    const filteredTermns = () => {
        return glossary.filter((o) => {
            return o.get("title").toLowerCase().includes(filter.toLowerCase())
                || o.get("description").toLowerCase().includes(filter.toLowerCase())
        })
    }
    return <div>
        <div className="row justify-content-end mt-4 mb-3">
            <div className="col-auto">
                <button type="button" className="btn background-trulivers btn-primary" onClick={() => {
                    setFormData({ ...emptyGlossary });
                    setCurrent(undefined)
                }}>Nuevo Término</button>
            </div>
        </div>
        <form onSubmit={handleSubmit}>
            <div className="mb-3">
                <input type="text" className="form-control" name="title" placeholder="Termino" required value={formData.title} onChange={handleInputChange} />
            </div>
            <div className="mb-3">
                <textarea type="text" className="form-control" name="description" placeholder="Descripcion" required value={formData.description} onChange={handleInputChange} />
            </div>
            <button type="submit" className="btn background-trulivers btn-primary">Guardar</button>
        </form>
        <h1 className="mt-5">Términos</h1>
        <div className="mb-3">
            <input type="text" className="form-control" name="filter" placeholder="Filtrar" required value={filter} onChange={(e) => {
                setFilter(e.target.value)
            }} />
        </div>
        <ol className="list-group list-group-numbered mt-3 mb-5">
            {filteredTermns().map((termn, index) => {
                return <li className="list-group-item d-flex justify-content-between align-items-start" onClick={() => {
                    setCurrent(termn)
                    setFormData({ title: termn.get("title"), description: termn.get("description") });
                }} key={index}>
                    <div className="ms-2 me-auto">
                        <div className="fw-bold">{termn.get("title")}</div>
                        {termn.get("description")}
                    </div>
                </li>
            })}

        </ol>

    </div>
}

export default Glossary