import './CardIconInfo.css'

const CardIconInfo = ({icon,text}) => {
    return (
        <>
            <div className="card mt-2">
                <div className="card-body row align-items-center">
                    <div className="col-auto">
                        <img className="icon" src={icon} alt="icon-status"/>
                    </div>
                    <div className="col">
                        {text}
                    </div>
                </div>
            </div>
        </>
    )
}
export default CardIconInfo