import parse from 'parse'
import { SurveyFields, getFieldsData } from '../../components/SurveyFields/SurveyFields'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

const questions = [
    {
        type: "text",
        title: "¿Qué recursos tienes para ingresar a la universidad?",
        hint: "Ej. El dinero de mis padres, becas del gobierno, ayuda de ICETEX, ninguno",
        id: "question1"
    },
    {
        type: "text",
        title: "¿Que condiciones en tu barrio y en tu casa favorecen el ingreso a la universidad?",
        hint: "Ej. Mi barrio queda cerca, pasa 1 ruta de bus que me lleva directo, ninguna",
        id: "question2"
    },
    {
        type: "text",
        title: "¿Que condiciones en tu barrio y en tu casa obstaculizan el ingreso a la universidad?",
        hint: "Ej. Mucha delincuencia, la distancia está a 2 o más buses, los servicios se cortan frecuentemente, ninguna",
        id: "question3"
    },
    {
        type: "text",
        title: "¿Cuáles son los medios de los cuales te informas de las universidades y opciones de estudio?",
        hint: "Ej. Internet, las universidades vienen al colegio, periódicos, vallas publicitarias, ninguno.",
        id: "question4"
    },
    {
        type: "text",
        title: "¿Qué carreras has contemplado para estudiar?",
        id: "question5"
    },
    {
        type: "text",
        title: "¿Cómo elegiste esta carrera?",
        id: "question6"
    },
    {
        type: "text",
        title: "¿Qué universidades has contemplado, para desarrollar tus estudios?",
        id: "question7"
    },
    {
        type: "text",
        title: "¿Tú, tus papás, algún familiar u otra persona han tramitado alguna beca o crédito para tus estudios universitarios?, ¿Quién? Y ¿con qué entidad?",
        id: "question8"
    },
    {
        type: "text",
        title: "¿Que información le gustaría encontrar que le ayude a profundizar en su decisión de carrera ?",
        id: "question9",
        hint: "Ej. Con qué perfil profesional estaría graduándome, cantidad de semestres a cursar, el valor de las carreras, nada"
    },
    {
        type: "radio",
        title: "¿A usted le gustaría que le ayudáramos a encontrar oportunidades de becas y financiamiento para sus estudios?",
        id: "question10",
        options: ["Si", "No"]
    },
    {
        type: "text",
        title: "¿Qué entidades ofrecen becas para estudios conoces?",
        id: "question11"
    },
    {
        type: "text",
        title: "¿Qué entidades te ofrecen créditos para estudios conoces?",
        id: "question12"
    },
    {
        type: "radio",
        title: "¿Estaría usted dispuesto a pagar por un servicio que le ayude a encontrar universidades, créditos, becas y acompañamiento en todo el proceso?",
        id: "question13",
        options: ["Si", "No", "Tal vez"]
    },
    {
        type: "radio",
        title: "¿Cuánto pagaría por un servicio como este?",
        id: "question14",
        options: ["$10.000 - $20.000", "$30.000 - $40.000", "$50.000 - $60.000"]
    },
    {
        type: "radio",
        title: "¿Este test de aptitud vocacional fue de utilidad para usted?",
        id: "question15",
        options: ["Si", "No"]
    },
    {
        type: "text",
        title: "¿Qué carrera te gustaría que te ayudáramos a conectar con las universidades? (hayan salido o no en el test)",
        id: "question16"
    },
]
const Survey = () => {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const sendSurvey = async (e) => {
        e.preventDefault()
        setLoading(true)
        await parse.Cloud.run("setSurvey", { id: localStorage.getItem("resultId"), survey: getFieldsData(e, questions) })
        localStorage.setItem("survey", true);
        setLoading(false)
        navigate("/sedsurvey")
    }
    return (
        <form className="text-start mt-5 mb-5" onSubmit={sendSurvey}>
            <h1 className="text-trulivers text-center">Encuesta perfil estudiante</h1>
            <SurveyFields questions={questions} />
            <button type="submit" className="btn background-trulivers btn-primary">{loading ? <div class="spinner-border text-light" role="status">
                <span class="visually-hidden">Loading...</span>
            </div> : "Enviar"}</button>
        </form>
    )
}

export default Survey