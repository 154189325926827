import { useNavigate } from "react-router-dom"
import StatusError from "../../components/StatusError/StatusError"
import StatusLoading from "../../components/StatusLoading/StatusLoading"
import '../../utils/global.css'
import { useEffect, useState } from "react"
import { joinAreasIds, sendAnswers } from "../../utils/tav"
import parse from 'parse'
import { getAdminReadACL } from "../../utils/parseController"

const Result = () => {
    const [result, setResult] = useState()
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState()
    const navigate = useNavigate();
    useEffect(() => {
        if (!localStorage.getItem("answers") && !localStorage.getItem("result")) navigate('/test')
        if (localStorage.getItem("answers") && !isLoading && !result) process()
        else if (localStorage.getItem("result")) process()
    }, []);
    const process = async () => {
        setLoading(true);
        if (localStorage.getItem("answers")) await getResult()
        else setResult(await joinAreasIds(JSON.parse(localStorage.getItem("result"))))
        setLoading(false);
    }
    const getResult = async () => {
        sendAnswers(JSON.parse(localStorage.getItem("answers")), async (resp) => {
            let data = await joinAreasIds(resp.slice(0, 3))
            setResult(data)
            const Result = parse.Object.extend("Result")
            const result = new Result()
            result.set("user", JSON.parse(localStorage.getItem("user")))
            result.set("result", data)
            result.set("answers", JSON.parse(localStorage.getItem("answers")))
            result.set("code", localStorage.getItem("code"))
            result.set("from", "trulivers-web")
            result.set("time", Number(localStorage.getItem("time")))
            result.setACL(getAdminReadACL())
            let resultObject = await result.save()
            localStorage.clear();
            localStorage.setItem("result", JSON.stringify(data))
            localStorage.setItem("resultId", resultObject.id)
        }, (error) => {
            console.log(error)
            setError(error.message);
        })
    }
    if (isLoading) return <StatusLoading />
    else if (error) return <StatusError error={error} />
    else if (result) return <div className="text-center">

        <h1 className="text-trulivers">Resultado</h1>
        <p><b>Estas son las áreas más compatibles con tu perfil</b></p>
        <div className="d-grid gap-2 mb-5">
            {
                result?.map((option, index) => {
                    return <button key={`result-${index}`} className={`btn btn-outline-primary`} >
                        {option.title}
                    </button>
                })
            }

        </div>
        <p><b>¿Quieres profundizar aún más? Prueba nuestra IA con solo responder 10 preguntas, tendrás un análisis profesional.</b></p>
        <button className="btn btn-primary background-trulivers mb-5" onClick={() => {
            navigate(`/ia`)
        }}>Probar con IA</button>
        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
            <button className="btn btn-primary background-trulivers" type="button" onClick={() => navigate("/survey")}>Continuar</button>
        </div>
    </div>
}

export default Result