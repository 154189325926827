import axios from 'axios';
import parse from 'parse';

const caw = 'https://lui2mi-corsanywhere.fly.dev/'
const API = `${caw}http://191.96.251.140:8000`;
const mocklet = `${caw}https://api.mocklets.com/p68342`;
export const getQuestions = (success, error) => {

  axios.get(`${API}/questions?type=kevin`)
    .then((response) => {
      success(response.data.data);
    })
    .catch((e) => {
      error(e);
    })

};
export const sendAnswers = async (answers, success, error) => {
  axios.post(`${API}/answer?type=kevin`, answers)
    .then((response) => {
      success(response.data.data);
    })
    .catch((e) => {
      error(e);
    });
};

export const getAreas = async () => {
  return await getRequest(`${mocklet}/areas`)
}

export const getCareers = async () => {
  return await getRequest(`${mocklet}/careers`)
}

export const sendStatistic = async (result, user, answers, code) => {
  let config = {
    headers: {
      'X-Parse-Application-Id': 'hgSrGX8QvZYqJa2S7vsIbkEREPg7RyrA6LZF3jOE',
      'X-Parse-REST-API-Key': '03dCp5uZ6sKnAvR9Bel1u5B7YHz9mijJbhG5H0NV',
      'Content-Type': 'application/json',
    }
  }
  axios.post('https://parseapi.back4app.com/classes/Result', { result: result, user: user, answers: answers, from: 'trulivers-web', code: code }, config)
    .then((resp) => {
      localStorage.clear();
    }).catch((e) => {
      console.log("statistics", "error", e)
    });
}

export const validateCode = async (code, success, fail) => {
  let config = {
    headers: {
      'X-Parse-Application-Id': 'hgSrGX8QvZYqJa2S7vsIbkEREPg7RyrA6LZF3jOE',
      'X-Parse-REST-API-Key': '03dCp5uZ6sKnAvR9Bel1u5B7YHz9mijJbhG5H0NV',
      'Content-Type': 'application/json',
    }
  }
  axios.get(`https://parseapi.back4app.com/classes/Code?where={"objectId":"${code}"}`, config)
    .then((resp) => {
      success(resp.data.results)
    }).catch((e) => {
      fail()
      console.log("validateCode", "error", e)
    });
}

export const joinAreasIds = async (_result) => {
  let areas = await new parse.Query("Area").containedIn("name", _result.map((area) => area.title)).find()
  _result.forEach(area => {
    area.id = areas.find((_area) => _area.get("name") === area.title).id
  });
  return _result
}

const getRequest = async (url) => {
  try {
    let response = await fetch(url);
    return await response.json();
  } catch (e) { }
  return []
}
