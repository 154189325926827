import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import logo from '../../assets/logo_name_blue.png'
import './layout.css'
import Modal from "../../components/Modal/modal"
import { useState } from "react"
import ModalGlossary from "../../components/ModalGlossary/modalGlossary"
import back from '../../assets/arrow.png'


const Layout = () => {
    const [showGlossary, setShowGlossary] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();
    return (
        <>
            <nav className="navbar bg-light">
                <div className="container-fluid">
                    <a className="navbar-brand m-0">
                        {location.pathname !== "/"?
                        <img className="" src={back} style={{ height: 32, width: 32, marginLeft: 0 }} onClick={() => navigate(-1)}/>: ""}
                    </a>
                    <Link to="/admin">
                        <img id="logo" src={logo} alt="logo" />
                    </Link>
                    <button className="btn btn-light m-0 p-0" onClick={() => setShowGlossary(true)}><h1 className="align-middle"><b>?</b></h1></button>
                </div>
            </nav>
            <div className="mt-4 container">
                <div className="row justify-content-center">
                    <div className="col-xs-12 col-md-10 col-l-8 col-xl-6">
                        <Outlet />
                    </div>
                </div>
            </div>
            <Modal locked={false} open={showGlossary} onClose={() => {
                setShowGlossary(false)
            }}>
                <ModalGlossary close={() => setShowGlossary(false)}></ModalGlossary>
            </Modal>

        </>

    )
}
export default Layout