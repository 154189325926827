import { useEffect, useState } from "react"
import parse from 'parse'
import { Link } from "react-router-dom"

const Areas = () => {
    const [areas, setAreas] = useState([])
    useEffect(() => {
        getAreas()
    }, [])
    const getAreas = async () => {
        let response = await new parse.Query("Area").find()
        setAreas(response)
    }
    return (
        <div className="pb-5">
            <ul className="list-group text-center">
                {areas.map((area, index) => {
                    return <li className="list-group-item" key={index}>
                        <Link to={`/area/${area.id}`}>
                            {area.get("name")}
                        </Link>
                    </li>
                })}
            </ul>
        </div>
    )
}

export default Areas