const SurveyFields = ({ questions }) => {
    return <>
        {questions.map((question, index) => {
            return <div className="mb-3" key={index}>
                <label htmlFor={question.id} className="form-label">
                    <b>{question.title}</b>
                    {question.hint ? <><br /><small>{question.hint}</small></> : <></>}
                </label>
                {
                    question.type === "text" ?
                        <input type="text" className="form-control" id={question.id} required /> :
                        question.options.map((option, index) => {
                            return <div className="form-check" key={index}>
                                <input className="form-check-input" type="radio" name={question.id} id={question.id} value={option} required />
                                <label className="form-check-label" htmlFor={question.id}>
                                    {option}
                                </label>
                            </div>
                        })
                }
            </div>
        })}
    </>
}
const getFieldsData = (e, questions) => {
    let surveyData = {}
    questions.forEach((question) => {
        surveyData[question.id] = e.target[question.id].value
    })
    return surveyData
}
export { SurveyFields, getFieldsData }